import React, { Component } from "react";
import { Label, Icon, Header } from "semantic-ui-react";

import { connect } from "react-redux";
import moment from "moment";
import DayMenuForm from "./dayMenuForm";

const csDaysInWeek = [
  "Pondělí",
  "Úterý",
  "Středa",
  "Čtvrtek",
  "Pátek",
  "Sobota",
  "Neděle",
];

export class EaterniaIndex extends Component {
  constructor(props) {
    super(props);
    moment.locale("de");

    this.state = { selectedWeek: this.getCurrentWeek(), weekMenu: [] };
  }

  getMondays(date) {
    var d = date || new Date(),
      month = d.getMonth(),
      mondays = [];

    d.setDate(1);

    // Get the first Monday in the month
    while (d.getDay() !== 1) {
      d.setDate(d.getDate() + 1);
    }

    // Get all the other Mondays in the month
    while (d.getMonth() === month) {
      const dateToPush = new Date(d.getTime());
      const obj = {
        key: dateToPush,
        text: dateToPush,
        value: dateToPush,
      };
      mondays.push(obj);
      d.setDate(d.getDate() + 7);
    }

    return mondays;
  }

  getCurrentWeek(startOfWeek) {
    startOfWeek = startOfWeek || moment().startOf("isoWeek");
    var endOfWeek = startOfWeek.clone().add(5, "d");

    var days = [];
    var day = startOfWeek;

    while (day <= endOfWeek) {
      days.push(day.toDate());
      day = day.clone().add(1, "d");
    }

    return days;
  }

  previousWeek = () => {
    const currentWeek = this.state.selectedWeek;
    const prevMonday = moment(currentWeek[0]).clone().add(-7, "d");
    this.setState({ selectedWeek: this.getCurrentWeek(prevMonday) });
  };

  nextWeek = () => {
    const currentWeek = this.state.selectedWeek;
    const nextMonday = moment(currentWeek[0]).clone().add(7, "d");
    this.setState({
      selectedWeek: this.getCurrentWeek(nextMonday),
    });
  };

  setMeal = (type, value, date) => {
    const menu = this.state.weekMenu;
    const selectedWeek = this.state.selectedWeek;
    const monday = moment(selectedWeek[0]).format("D.M YYYY");
    const meal = {
      date,
      type,
      value,
    };
    if (!menu[monday]) {
      menu[monday] = [];
    }

    menu[moment(selectedWeek[0]).format("D.M YYYY")][date] = meal;
    this.setState({ [date]: meal });
  };

  render() {
    // const mondays = [];
    // var monday = moment()
    //   .startOf("month")
    //   .day("Monday");
    // if (monday.date() > 7) monday.add(7, "d");
    // var month = monday.month();
    // while (month === monday.month()) {
    //   // document.body.innerHTML += "<p>" + monday.toString() + "</p>";
    //   const mondayArr = Object.assign(monday);
    //   mondays.push(mondayArr);
    //   monday.add(7, "d");
    // }

    const mondays = this.getMondays();

    // console.log(mondays);
    const { selectedWeek, weekMenu } = this.state;
    const monday = moment(selectedWeek[0]).format("D.M YYYY");

    return (
      <div>
        <Header as="h1">Eaternia - Denní menu</Header>
        <div>
          <div>
            <Icon
              size="large"
              name="angle left"
              onClick={this.previousWeek}
              fitted
            />{" "}
            <Label size="huge">
              Týden: {` `}
              {moment(selectedWeek[0]).format("D.M YYYY")} -
              {moment(selectedWeek[selectedWeek.length - 1]).format("D.M YYYY")}
            </Label>
            <Icon
              size="large"
              name="angle right"
              onClick={this.nextWeek}
              fitted
            />
          </div>
        </div>
        <DayMenuForm selectedWeek={selectedWeek} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EaterniaIndex);
