import React, { Component } from "react";
import { Table } from "semantic-ui-react";

import DayMenuRow from "./dayMenuRow";

export default class DayMenuForm extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {}

  render() {
    const { selectedWeek } = this.props;
    return (
      <React.Fragment>
        <Table celled striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Datum</Table.HeaderCell>
              <Table.HeaderCell>Polévka</Table.HeaderCell>
              <Table.HeaderCell>Hlavní Jídlo</Table.HeaderCell>
              <Table.HeaderCell>Salát</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {selectedWeek.map((weekDay, key) => (
              <DayMenuRow day={weekDay} key={key} />
            ))}
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}
