import React, { Component } from "react";
import { Label, Icon, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import {
  LEVEL_EATERNIA,
  LEVEL_ADMIN,
  LEVEL_AKCE,
} from "../../../consts/userLevel";

export class HomeIndex extends Component {
  render() {
    const { user } = this.props;

    return (
      <div>
        <Header as="h1">Vítejte v Administraci Eternia Webu!</Header>
        {user.userLevel === LEVEL_EATERNIA || user.userLevel === LEVEL_ADMIN ? (
          <div style={{ paddingTop: "10px" }}>
            <Link to="/eaternia">
              <Label color="teal" image size="huge">
                <Icon name="food" />
                Eatenia
                <Label.Detail>Denní menu</Label.Detail>
              </Label>
            </Link>
          </div>
        ) : (
          ""
        )}
        {user.userLevel === LEVEL_AKCE || user.userLevel === LEVEL_ADMIN ? (
          <div style={{ paddingTop: "10px" }}>
            <Link to="/events">
              <Label color="brown" image size="huge">
                <Icon name="warehouse" />
                Akce
                <Label.Detail>Akce v baráku</Label.Detail>
              </Label>
            </Link>
          </div>
        ) : (
          ""
        )}
        {/* <div style={{ paddingTop: "50px" }}>
          <Table basic="very" celled collapsing>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>TODO</Table.HeaderCell>
                <Table.HeaderCell>Kde?</Table.HeaderCell>
                <Table.HeaderCell>Priorita</Table.HeaderCell>
                <Table.HeaderCell>Hotovo?</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  Seznam uživatelů a adminitrace jejich práv
                </Table.Cell>
                <Table.Cell>Admin</Table.Cell>
                <Table.Cell>2</Table.Cell>
                <Table.Cell negative>Nope</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Navázat denní menu na web</Table.Cell>
                <Table.Cell>Web</Table.Cell>
                <Table.Cell>1</Table.Cell>
                <Table.Cell negative>Nope</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Navázat akce na web</Table.Cell>
                <Table.Cell>Web</Table.Cell>
                <Table.Cell>1</Table.Cell>
                <Table.Cell negative>Nope</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Správa fotek</Table.Cell>
                <Table.Cell>Web</Table.Cell>
                <Table.Cell>4</Table.Cell>
                <Table.Cell negative>Nope</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div> */}
      </div>
    );
  }
}
