import React, { Component } from "react";

import { auth, provider, firebase } from "./firebase";

import Layout from "./components/Layouts/default";

import { Image } from "semantic-ui-react";
import { StateProvider } from "./state";
import axios from "axios";

import "./App.css";
import { API_URL, API_PASS } from "./consts/apiUrl";

const adminUser = "admin@eternia.com";
const feathersAuth = `${API_URL}/authentication/`;
const feathersUser = {
  strategy: "local",
  email: adminUser,
  password: API_PASS,
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      firebaseInited: false,
    };
    this.login = async () => {
      auth.signInWithPopup(provider).then(async (response) => {
        const userFromDb = await this.checkIfUserExistInDb(
          response.user.uid,
          response
        );

        this.setState({
          userFromDb,
        });
      });
    };
    this.logout = () => {
      auth.signOut().then(() => {
        this.setState({
          user: null,
        });
      });
    };
  }

  async getFeathersToken() {
    const feathersToken = await axios.post(feathersAuth, feathersUser);
    localStorage.setItem("jwtToken", feathersToken.data.accessToken);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${feathersToken.data.accessToken}`;
  }

  checkIfUserExistInDb = (uid, result) => {
    return firebase
      .database()
      .ref("/users/" + uid)
      .once("value")
      .then((snapshot) => {
        const uid = (snapshot.val() && snapshot.val().uid) || null;
        if (uid === null) {
          const userObj = {
            uid: result.uid,
            username: result.displayName,
            email: result.email,
            userLevel: 0,
          };
          this.writeUserData(userObj);
          return userObj;
        } else {
          return snapshot.val();
        }
      });
  };

  writeUserData = (userObj) => {
    if (userObj.uid === undefined) return;
    firebase
      .database()
      .ref("users/" + userObj.uid)
      .set(userObj);
  };

  componentDidMount() {
    this.getFeathersToken();
    auth.onAuthStateChanged((response) => {
      if (response) {
        this.checkIfUserExistInDb(response.uid, response).then(async (user) => {
          this.setState({ user, firebaseInited: true });
        });
      } else {
        this.setState({ firebaseInited: true });
      }
    });
  }

  render() {
    const user = this.state.user;
    const firebaseInited = this.state.firebaseInited;
    const initialState = {
      theme: { primary: "green" },
    };

    const reducer = (state, action) => {
      switch (action.type) {
        case "changeTheme":
          return {
            ...state,
            theme: action.newTheme,
          };

        case "setAuth":
          return {
            ...state,
            theme: action.newTheme,
          };

        default:
          return state;
      }
    };

    return (
      <StateProvider initialState={initialState} reducer={reducer}>
        {firebaseInited ? (
          <Layout
            {...this.props}
            user={user}
            login={this.login}
            logout={this.logout}
          />
        ) : (
          <div
            style={{
              display: "flex",
              backgroundColor: "black",
              flexDirection: "column",
              position: "relative",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              width: "100vw",
            }}
          >
            <Image src="https://i.makeagif.com/media/12-05-2015/-3jB3E.gif" />
            <div
              style={{
                position: "absolute",
                marginTop: "150px",
                color: "white",
                fontSize: "30px",
              }}
            >
              Loading ...
            </div>
          </div>
        )}
      </StateProvider>
    );
  }
}

export default App;
