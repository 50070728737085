import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Icon, Table, Header, Segment } from "semantic-ui-react";
import { DateInput, DatesRangeInput } from "semantic-ui-calendar-react";
import _ from "lodash";

import axios from "axios";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { API_URL } from "../../../consts/apiUrl";

const eventsApi = `${API_URL}/events`;

const options = [
  { key: "puda", text: "Půda", value: "Půda" },
  { key: "underdogs", text: "Underdogs'", value: "Underdogs'" },
  { key: "eaternia", text: "Eaternia", value: "Eaternia" },
];

const now = new Date();
now.setHours(0, 0, 0, 0);

export class EventsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      calendarView: false,
      multipleDays: false,
      datum: "",
      nazev: "",
      misto: "",
      info: "",
      link: "",
      column: null,
      direction: null,
    };
  }

  componentDidMount() {
    this.fetchEvents();
  }

  submit = () => {
    this.insertEvent();
  };

  convertDateString = (datum) => {
    const dateParts = datum.split(".");
    return new Date(
      Date.parse(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`)
    );
  };

  fetchEvents = (cleanInputs = false) => {
    axios.get(`${API_URL}/events`).then((response) => {
      if (cleanInputs) {
        this.setState({
          events: this.sortEventsByDate(response.data),
          multipleDays: false,
          datum: "",
          nazev: "",
          misto: "",
          info: "",
          link: "",
        });
      } else {
        this.setState({ events: this.sortEventsByDate(response.data) });
      }
    });
  };

  sortEventsByDate = (events) =>
    events.sort((a, b) => {
      const aSplit = a.datum.split("-")[0].trim();
      const bSplit = b.datum.split("-")[0].trim();
      return this.convertDateString(aSplit) - this.convertDateString(bSplit);
    });
  removeEvent = (id) => {
    axios.delete(`${eventsApi}/${id}`).then(() => this.fetchEvents());
  };

  hideEvent = (event, e) => {
    console.log(event.zobrazeno);
    axios
      .patch(`${eventsApi}/${event["_id"]}`, {
        zobrazeno: !event.zobrazeno,
      })
      .then(() => this.fetchEvents());
  };

  insertEvent = () => {
    const formData = Object.assign(this.state);
    formData.zobrazeno = true;
    delete formData.events;
    delete formData.calendarView;
    delete formData.column;
    delete formData.direction;

    axios.post(`${API_URL}/events`, formData).then((response) => {
      this.fetchEvents(true);
    });
  };

  handleChange = (e, { name, value }) => {
    this.setState({
      [name]: value,
    });
  };

  handleMultipleDays = () => {
    const multipleDays = !this.state.multipleDays;
    this.setState({ multipleDays: multipleDays });
  };

  handleSort = (clickedColumn) => () => {
    const { column, events, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(events, [clickedColumn]),
        direction: "ascending",
      });

      return;
    }

    this.setState({
      data: events.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  render() {
    const { column, events, direction, multipleDays } = this.state;

    return (
      <div>
        <Header as="h1">Akce</Header>
        <Segment>
          <Header as="h2">Přidat Akci</Header>
          <Form autoComplete="off">
            <Form.Checkbox
              checked={multipleDays}
              onChange={this.handleMultipleDays}
              label="Vícedenní akce"
            />
            <Form.Group widths="equal">
              {multipleDays ? (
                <DatesRangeInput
                  localization="cs"
                  dateFormat={"D. M. YYYY"}
                  autoComplete="off"
                  name="datum"
                  label="Datum"
                  placeholder="Datum"
                  value={this.state.datum}
                  iconPosition="left"
                  onChange={this.handleChange}
                />
              ) : (
                <DateInput
                  localization="cs"
                  dateFormat={"D. M. YYYY"}
                  autoComplete="off"
                  name="datum"
                  label="Datum"
                  placeholder="Datum"
                  value={this.state.datum}
                  iconPosition="left"
                  onChange={this.handleChange}
                />
              )}
              <Form.Select
                name="misto"
                onChange={this.handleChange}
                value={this.state.misto}
                fluid
                label="Místo"
                options={options}
                placeholder="Místo"
              />
            </Form.Group>
            <Form.Input
              name="nazev"
              onChange={this.handleChange}
              value={this.state.nazev}
              fluid
              label="Název"
              placeholder="Název"
            />
            <Form.Input
              name="link"
              value={this.state.link}
              onChange={this.handleChange}
              fluid
              label="Odkaz"
              placeholder="Odkaz"
            />
            <Form.TextArea
              label="Info"
              name="info"
              value={this.state.info}
              onChange={this.handleChange}
              placeholder="Tell us more about you..."
            />
            <Form.Button onClick={this.submit}>Uložit</Form.Button>
          </Form>
        </Segment>
        <Segment>
          <Header as="h2">Uložené Akce</Header>
          <Table celled sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === "datum" ? direction : null}
                  onClick={this.handleSort("name")}
                >
                  Datum
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "info" ? direction : null}
                  onClick={this.handleSort("name")}
                >
                  Info
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "misto" ? direction : null}
                  onClick={this.handleSort("name")}
                >
                  Místo
                </Table.HeaderCell>
                <Table.HeaderCell>Akce</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.events.map((event, key) => (
                <Table.Row
                  key={key}
                  warning={this.convertDateString(event.datum) < now}
                >
                  <Table.Cell>{event.datum}</Table.Cell>
                  <Table.Cell>
                    <div>
                      <div>{event.nazev}</div>
                      <div>{event.info}</div>
                      <div>
                        <a href={event.link}>{event.link}</a>
                      </div>
                    </div>
                  </Table.Cell>

                  <Table.Cell>{event.misto}</Table.Cell>
                  <Table.Cell>
                    <div onClick={this.hideEvent.bind(this, event)}>
                      <Icon
                        name={event.zobrazeno ? "eye" : "eye slash"}
                        size="large"
                      />
                    </div>
                    <div
                      onClick={this.removeEvent.bind(
                        this,
                        event["_id"],
                        event.zobrazeno
                      )}
                    >
                      <Icon name="trash" size="large" />
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EventsIndex);
