import React from "react";
import {
  Icon,
  Container,
  Header,
  Image,
  Menu,
  Button,
} from "semantic-ui-react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { EaterniaIndex } from "../../components/Pages/Eaternia";
import { EventsIndex } from "../../components/Pages/Events";
import { HomeIndex } from "../../components/Pages/Home";
import { useStateValue } from "../../state";

const LEVEL_AUTH_USER = 1;
const LEVEL_ADMIN = 666;
const LEVEL_EATERNIA = 100;
const LEVEL_AKCE = 200;

const PrivateRoute = ({ component: Component, user, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      user ? (
        <Component {...props} user={user} />
      ) : (
        <div>
          Login in or got to hell!
          <Image src={"http://i.imgur.com/akUOxRL.gif"} />
        </div>
      )
    }
  />
);

const Layout = (props) => {
  const { user, signOut, signInWithGoogle, login, logout } = props;
  const [{ theme, providers, firebaseAppAuth }, dispatch] = useStateValue();

  let userLevel = 0;
  if (user) {
    userLevel = user.userLevel;
  }

  const UcetNeaktivni = ({ user, login, logout }) => (
    <div style={{ paddingTop: "20px" }}>
      <div
        style={{
          display: "flex",
          width: "100vw",
          height: "60vh",
          alignContent: "center",
          justifyItems: "center",
          justifyContent: "center",
          alignItems: "center",
          padding: "0 20px",
        }}
      >
        {user ? (
          <div>
            <Header>
              Účet není aktivován. Kontaktuje prosím administrátora. Pokud
              nevíte, kdo je administrátor, asi tu jste špatně...
            </Header>
            <div>
              <Button onClick={logout} size="huge" color="red">
                Odhlásit se
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div>
              <Button onClick={login} size="huge" color="green">
                <Icon name="google" />
                Příhlást pomocí Google
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div>
      {userLevel > 0 ? (
        <Router>
          <React.Fragment>
            <Menu fixed="top" inverted>
              <Container>
                <Menu.Item as="a" header>
                  Eaternia Admin
                </Menu.Item>
                <Menu.Item>
                  <Link to="/">Home</Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to="/eaternia">Eaternia</Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to="/events">Akce</Link>
                </Menu.Item>
                <Menu.Item position="right">
                  {user ? (
                    <p>
                      Ahoj, {user.username} (Lvl {user.userLevel})
                    </p>
                  ) : (
                    ""
                  )}
                </Menu.Item>
                <Menu.Item>
                  {user ? <button onClick={logout}>Odhlásit se</button> : ""}
                </Menu.Item>
              </Container>
            </Menu>

            <Container
              fluid
              style={{
                marginTop: "7em",
                paddingLeft: "4em",
                paddingRight: "4em",
              }}
            >
              <div>{props.children}</div>
              <PrivateRoute exact path="/" user={user} component={HomeIndex} />
              <PrivateRoute
                exact
                path="/eaternia"
                user={user}
                component={EaterniaIndex}
              />
              <PrivateRoute
                exact
                path="/events"
                user={user}
                component={EventsIndex}
              />
            </Container>
          </React.Fragment>
        </Router>
      ) : (
        <UcetNeaktivni user={user} login={login} logout={logout} />
      )}
    </div>
  );
};

export default Layout;
