const firebase = require("firebase");
const config = {
  apiKey: "AIzaSyAY-gN-CqryIfOHXXE5dad5tZcKMe0zcsA",
  authDomain: "eternia-59790.firebaseapp.com",
  databaseURL: "https://eternia-59790.firebaseio.com",
  projectId: "eternia-59790",
  storageBucket: "eternia-59790.appspot.com",
  messagingSenderId: "1004055333815"
};
firebase.initializeApp(config);
const provider = new firebase.auth.GoogleAuthProvider();
const auth = firebase.auth();
module.exports = {
  firebase,
  provider,
  auth
};
