import React, { Component } from "react";
import { Table, Input } from "semantic-ui-react";
import moment from "moment";
import axios from "axios";
import { API_URL } from "../../../consts/apiUrl";

const csDaysInWeek = [
  "Pondělí",
  "Úterý",
  "Středa",
  "Čtvrtek",
  "Pátek",
  "Sobota",
  "Neděle",
];

export default class DayMenuRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datum: moment(this.props.day).format("D-M-YYYY"),
      polevka: "",
      hlavniJidlo: "",
      salat: "",
    };
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;

    if (oldProps.day !== newProps.day) {
      this.fetchMenuData(newProps.day, true);
    }
  }

  componentDidMount() {
    this.fetchMenuData(this.props.day);
  }

  fetchMenuData(date, clearInputsIfEmtpy = false) {
    const dateDbFriendly = moment(date).format("D-M-YYYY");
    const storedData = axios
      .get(`${API_URL}/eaternia-lunch?datum=${dateDbFriendly}`)
      .then((response) => {
        if (response.data.data.length > 0) {
          this.setState({
            datum: dateDbFriendly,
            polevka: response.data.data[0].polevka,
            salat: response.data.data[0].salat,
            hlavniJidlo: response.data.data[0].hlavniJidlo,
          });
        } else if (clearInputsIfEmtpy) {
          this.setState({
            datum: dateDbFriendly,
            polevka: "",
            salat: "",
            hlavniJidlo: "",
          });
        }
      });
  }

  saveData = () => {
    axios.post(`${API_URL}/eaternia-lunch`, {
      ...this.state,
    });
  };

  handleChange = (e, { name, value }) => {
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { day: weekDay } = this.props;
    const { polevka, hlavniJidlo, salat } = this.state;

    return (
      <Table.Row>
        <Table.Cell>
          {csDaysInWeek[moment(weekDay).isoWeekday() - 1]}
          {` `}
          {moment(weekDay).format("D.M YYYY")}
        </Table.Cell>
        <Table.Cell>
          <Input
            onChange={this.handleChange}
            onBlur={this.saveData}
            name="polevka"
            fluid
            placeholder="Polévka"
            value={polevka}
          />
        </Table.Cell>
        <Table.Cell>
          <Input
            onChange={this.handleChange}
            onBlur={this.saveData}
            name="hlavniJidlo"
            fluid
            placeholder="Hlavní Jídlo"
            value={hlavniJidlo}
          />
        </Table.Cell>
        <Table.Cell>
          <Input
            onChange={this.handleChange}
            onBlur={this.saveData}
            name="salat"
            fluid
            placeholder="Salat"
            value={salat}
          />
        </Table.Cell>
      </Table.Row>
    );
  }
}
